<template>
  <div>
    <div class="p-3 bg-white rounded-xl shadow-lg mx-4">
      <div class="grid grid-cols-2" @click="showFilters = !showFilters">
        <div class="flex gap-3 items-center">
          <a-icon type="filter"></a-icon>
          <h2 class="text-left text-lg text-gray-600">
            <b>Filtros de búsqueda</b>
          </h2>
        </div>
        <span
          class="h-5 w-5 m-2 flex justify-center items-center transition justify-self-end"
          :class="{ 'transform rotate-180': showFilters }"
        >
          <a-icon type="caret-down" class="text-xl my-auto" />
        </span>
      </div>
      <a-divider v-if="showFilters" class="mt-2 mb-2"></a-divider>
      <div class="flex gap-5 flex-wrap relative" v-show="showFilters">
        <div
          @keypress.enter="!disabled || !disableButton ? onSearch() : ''"
          class="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 relative"
        >
          <BaseSelect
            ref="company"
            id="entidad"
            label="Entidad"
            :selectOptions="companies"
            v-model="form.nombre_entidad"
            :loading="loading"
          />

          <BaseInput
            type="text"
            label="Código de queja / Id. radicado"
            placeholder="Ej: 26598965472981"
            v-model="form.codigo_queja"
          />
          <BaseSelect
            ref="states"
            id="estado"
            label="Estado de la queja"
            class="self-end"
            :selectOptions="state"
            v-model="form.estado"
            :loading="loading"
          />

          <BaseSelect
            ref="identificacion"
            id="identification"
            label="Tipo de identificación"
            :selectOptions="identificationTypes"
            v-model="form.tipo_identificacion_CF"
            :loading="loading"
          />

          <BaseInput
            type="text"
            label="No. de identificación"
            placeholder="Ej: 1144578546"
            v-model="form.numero_id_CF"
          />

          <BaseSelect
            ref="states_duplicity"
            id="duplicity"
            label="Estado de duplicidad"
            :selectOptions="duplicityState"
            v-model="form.estado_duplicidad_procesado"
            :loading="loading"
          />
        </div>
        <div
          class="w-full flex justify-self-end self-end relative gap-1 left-3/4"
        >
          <a-button
            :loading="(searching = loading)"
            type="primary"
            shape="round"
            @click="onSearch"
            class="w-1/4 gap-2 h-10 sm:mt-auto mt-3"
            :disabled="disableButton && disabled"
          >
            <a-icon type="search" v-if="!searching"></a-icon>
            {{ searching ? "BUSCANDO..." : "BUSCAR" }}
          </a-button>
        </div>
        <div
          v-if="loading"
          class="absolute w-full h-full flex items-center justify-center"
        >
          <a-spin>
            <a-icon
              slot="indicator"
              type="loading"
              style="font-size: 64px"
              spin
            />
          </a-spin>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { monthsToYears } from "date-fns";
import { mapState } from "vuex";
export default {
  props: ["query", "loading"],
  data() {
    return {
      form: {
        tipo_identificacion_CF: "",
        numero_id_CF: "",
        nombre_entidad: "",
        codigo_queja: "",
        estado: "",
        estado_duplicidad_procesado: "",
      },
      feedback: "",
      searching: false,
      disableButton: true,
      showFilters: false,
      oldFilters: {},
    };
  },

  watch: {},
  methods: {
    onSearch() {
      this.searching = true;

      if (this.changedFilters()) {
        this.$emit("fetch-complaints", this.form);
        this.oldFilters = this.form;
        this.feedback = "";
      }
    },
    setRangeDate(eventValue) {
      this.form.createBetween = eventValue;
      this.disableButton = false;
    },
    changedFilters() {
      // let aKeys = Object.keys(this.form).sort();
      // let bKeys = Object.keys(this.oldFilters).sort();
      // if (aKeys.length != bKeys.length) return true;
      // for (let i = 0; i < aKeys.length; i++) {
      //   if (this.form[aKeys[i]] !== this.oldFilters[bKeys[i]]) {
      //     return true;
      //   }
      // }
      return true;
    },

    showNotification(message) {
      this.$notification.open({
        message: "Notificación",
        description: message,
      });
    },
  },
  computed: {
    formOptions: function () {
      return this.$store.state.form;
    },
    companies: function () {
      return this.formOptions.optionsCompanies.map((company) => {
        return {
          name: company.name,
          id: company.name,
        };
      });
    },
    identificationTypes: function () {
      return this.formOptions.optionsIdentificationType.map((types) => {
        return { name: types.name, id: types.name };
      });
    },
    duplicityState: function () {
      return [
        { id: "Duplicada", name: "Duplicada" },
        { id: "Principal", name: "Principal" },
      ];
    },
    state: function () {
      return [
        { id: "Recibida", name: "Recibida" },
        { id: "Abierta", name: "Abierta" },
      ];
    },
    disabled: function () {
      // let isFormEmpty = true;
      // Object.values(this.form).forEach((value) => {
      //   isFormEmpty = isFormEmpty && !value;
      // });
      // return isFormEmpty;
      return false;
    },
  },
  created() {
    this.form = { ...this.$props.query };
    this.oldFilters = { ...this.form };
  },
};
</script>

<style></style>
